.page {
  display: flex;
  justify-content: center;
}

.page__content {
  width: 100%;
  max-width: 900px;
  margin: 2rem;
}

.page__content__breadcrumb{
  margin-top: -5px;
}

.story{
    cursor: pointer;
}

.pointer{
  cursor: pointer;
}


.navigation{
  position : sticky;
	top :0;
  background: white;
}

.navigation__tabs{
  position: absolute;
  display: flex;
  /* font-size: 60px; */
  /* top: 10px; */
  height: 100%;
  width: 100%;
}

.navigation__tabs__tab{
  display: flex;
    flex-direction: column;
}

a.navigation__tabs__tab{
  display: flex;
  flex-direction: column-reverse;
  color: transparent;
  padding-bottom: 2.1%;
}

.navigation__tabs__tab1{
  width: 15%;
  margin-left: 2%;
}

.navigation__tabs__tab2{
  width: 18%;
  margin-left: 1%;
}

.navigation__tabs__tab3{
  width: 13%;
  margin-left: 2%;

}

.navigation__tabs__tab4{
  width: 13%;
  margin-left: 2%;
}
